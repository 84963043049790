
const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};


const capitalizeArray = arr => arr.map(item => capitalize(item)).join(' ');

export function generatedBreadCrumb(pathname, pageName) {
  const wilsonPath = pathname.substring(1);
  let paths = wilsonPath.split('/');
  if (pageName) {
    const name = pageName.split(' ').join('-');
    const found = paths.find(element => element.toLowerCase() === name.toLowerCase());
    if (!found) {
      paths.push(name);
    }
  }
  const output = [];
  // remove the last element if there was a / at the end of the pathname
  paths = paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;


  paths = paths[1] === '' ? paths.slice(1) : paths;

  paths.forEach((path, index, array) => {
    if (paths.length > 1) {
      if (index === 0) {
        if (path !== 'en' && path !== 'it' && path !== 'ca' && path !== 'de' && path !== 'fr' && path !== 'ru') {
          const breadcrumbText = capitalizeArray(path.split('-'));
          output.push({ breadcrumbURL: array[index], breadcrumbText });
        }
      } else if (index === paths.length - 1) {
        const breadcrumbText = capitalizeArray(path.split('-'));
        output.push({ breadcrumbURL: undefined, breadcrumbText });
      } else {
        // Build the path for the current URL
        const url = paths.slice(0, index + 1).join('/');
        const breadcrumbText = capitalizeArray(path.split('-'));
        output.push({ breadcrumbURL: url, breadcrumbText });
      }
    } else {
      const breadcrumbText = capitalizeArray(path.split('-'));
      output.push({ breadcrumbURL: undefined, breadcrumbText });
    }
  });
  // Return a list of links
  return output;
}
