 
import './ficha_tiendas.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import Loki from '../src/components/Loki/lokipane';
import { MagniLeft } from '../src/components/Magni/MagniLeft';
import { MagniRight } from '../src/components/Magni/MagniRight';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import { Saga } from '../src/components/Saga/Saga';
import { generatedBreadCrumb } from '../src/helpers/breadCrumbs';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { shop_name } }) => graphql`
  query($shop_name: String!, $locale: String!) {
    allShopsDetailsHeaderBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allShopsDetailsSeoData(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allShopsDetailsImageHeaderBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShopsDetailsSagaBlock(
      filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          image
          image_alt
          position
        }
      }
    }
    allShopsDetailsVideoSagaBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          video
        }
      }
    }
    allShopsDetailsTextSagaBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          summary
          textBox
          ctaText1
          cta1
        }
      }
    }
    allShopsDetailsLokiImages(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    bigLokiPics: allShopsDetailsLokiImages(
      filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allShopsDetailsMagniBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          left_link_copy
          left_link_path
          right_link_copy
          right_link_path
        }
      }
    }
    allShopsMosaicThreeBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tag
          title
          image_alt
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allShopsDetailsBreadCrumbBlock(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allShopsDetailsRichData(filter: { tag: { eq: $shop_name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class shopDetails extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allShopsDetailsHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allShopsDetailsHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allShopsDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const sagaData = {
      image: this.props.data.allShopsDetailsSagaBlock.edges[0].node.image,
      altImage: this.props.data.allShopsDetailsSagaBlock.edges[0].node.image_alt,
      video: this.props.data.allShopsDetailsVideoSagaBlock.edges[0].node.video,
      title: this.props.data.allShopsDetailsTextSagaBlock.edges[0].node.title,
      summary: this.props.data.allShopsDetailsTextSagaBlock.edges[0].node.summary,
      social: false,
      button1: {
        cta: this.props.data.allShopsDetailsTextSagaBlock.edges[0].node.cta1,
        size: 'alone',
        color: 'orange',
        ctaText: this.props.data.allShopsDetailsTextSagaBlock.edges[0].node.ctaText1,
      },
      button2: {
        cta: tt('/descubrir', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('DESCUBRIR', this.props.pageContext.locale),
      },
    };

    const PrepareforLoki = () => ({
      arrayImg: this.props.data.allShopsDetailsLokiImages.edges
        .map(e => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter(e => e.img !== undefined),
      bigArrayImg: this.props.data.bigLokiPics.edges
        .map(e => ({
          img: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
        }))
        .filter(e => e.img !== undefined),
    });

    const PrepareforFreya = src => ({
      cards: src.edges
        .map(e => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: 'promoted',
          title: e.node.title,
          buttons: e.node.buttons,
        }))
        .filter(e => e.image !== undefined),
    });

    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allShopsDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allShopsDetailsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="shop-details-content">
              <div className="top-shop-details">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  options={this.props.pageContext.options}
                  title={this.props.data.allShopsDetailsBreadCrumbBlock.edges[0].node.name}
                />
                <Hod
                  data={this.props.data.allShopsDetailsRichData.edges[0].node.rich_markup}
                  url={this.props.pageContext.url}
                  img={
                    this.props.data.allShopsDetailsImageHeaderBlock.edges[0].node.localImage
                      .childImageSharp.fluid
                  }
                />
              </div>
              {!this.props.pageContext.url.includes('caribe') ? (
                <div className="shop-arrows-content">
                  <MagniLeft data={this.props.data.allShopsDetailsMagniBlock.edges[0].node} />
                  <MagniRight data={this.props.data.allShopsDetailsMagniBlock.edges[0].node} />
                </div>
              ) : null}

              <BlueArrow />
              <Saga data={sagaData} />
              {this.props.data.allShopsDetailsLokiImages.edges.length > 1 && (
                <H4 title={tt('GALERIA DE FOTOS', this.props.pageContext.locale)} />
              )}
              {this.props.data.allShopsDetailsLokiImages.edges.length > 1 && (
                <Loki data={PrepareforLoki()} />
              )}

              {!this.props.pageContext.url.includes('caribe') ? (
                <div className="promotion-content">
                  <H4 title={tt('EXPLORA OTRAS TIENDAS', this.props.pageContext.locale)} />
                  <Freya data={PrepareforFreya(this.props.data.allShopsMosaicThreeBlock)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
shopDetails.propTypes = {
  shopDetailsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default shopDetails;
